import PropTypes from 'prop-types';

/**
 * Core
 */
export const urlGenerator = PropTypes.shape({
    route: PropTypes.func.isRequired,
});

export const match = PropTypes.shape({
    params: PropTypes.object.isRequired,
});

export const intl = PropTypes.shape({
    locale: PropTypes.string.isRequired,
    formatMessage: PropTypes.func.isRequired,
});

export const message = PropTypes.shape({
    id: PropTypes.string.isRequired,
    defaultMessage: PropTypes.string,
});

export const label = PropTypes.oneOfType([message, PropTypes.node]);

/**
 * Site
 */

export const video = PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    thumbnail: PropTypes.string,
    sources: PropTypes.shape({
        mp4: PropTypes.string.isRequired,
        webm: PropTypes.string,
    }),
});

export const videos = PropTypes.arrayOf(video);

export const group = PropTypes.shape({
    title: PropTypes.string,
    videos: PropTypes.array,
});

export const groups = PropTypes.arrayOf(group);
