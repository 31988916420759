import { getLocaleFromLocation } from '../lib/utils';

import PresentationThumb from '../img/thumbnails/presentation.jpg';

import AmitieEtAmourThumb from '../img/thumbnails/amitie-et-amour.jpg';
import BattementDailesThumb from '../img/thumbnails/battement-d-ailes.jpg';
import DefleureeThumb from '../img/thumbnails/defleuree.jpg';
import HistoireDePunPunThumb from '../img/thumbnails/histoire-de-pun-pun.jpg';
import LoveTriangleThumb from '../img/thumbnails/love-triangle.jpg';
import OuEstLamourThumb from '../img/thumbnails/ou-est-l-amour.jpg';
import PremierBaiserThumb from '../img/thumbnails/premier-baiser.jpg';
import SisypheThumb from '../img/thumbnails/sisyphe-alej-elo.jpg';
import TrentaineRencontresThumb from '../img/thumbnails/trentaine-rencontres.jpg';
import UnePremiereRencontreThumb from '../img/thumbnails/une-premiere-rencontre.jpg';
import UneTasseDamourThumb from '../img/thumbnails/une-tasse-d-amour.jpg';
import VieDeReveThumb from '../img/thumbnails/vie-de-reve.jpg';

import PresentationMp4 from '../videos/mp4/presentation.mp4';

import AmitieEtAmourMp4 from '../videos/mp4/amitie-et-amour.mp4';
import BattementDailesMp4 from '../videos/mp4/battement-d-ailes.mp4';
import DefleureeMp4 from '../videos/mp4/defleuree.mp4';
import HistoireDePunPunMp4 from '../videos/mp4/histoire-de-pun-pun.mp4';
import LoveTriangleMp4 from '../videos/mp4/love-triangle.mp4';
import OuEstLamourMp4 from '../videos/mp4/ou-est-l-amour.mp4';
import PremierBaiserMp4 from '../videos/mp4/premier-baiser.mp4';
import SisypheMp4 from '../videos/mp4/sisyphe-alej-elo.mp4';
import TrentaineRencontresMp4 from '../videos/mp4/trentaine-rencontres.mp4';
import UnePremiereRencontreMp4 from '../videos/mp4/une-premiere-rencontre.mp4';
import UneTasseDamourMp4 from '../videos/mp4/une-tasse-d-amour.mp4';
import VieDeReveMp4 from '../videos/mp4/vie-de-reve.mp4';

import AmitieEtAmourWebm from '../videos/webm/amitie-et-amour.webm';
import BattementDailesWebm from '../videos/webm/battement-d-ailes.webm';

import HistoireDePunPunWebm from '../videos/webm/histoire-de-pun-pun.webm';
import LoveTriangleWebm from '../videos/webm/love-triangle.webm';
import OuEstLamourWebm from '../videos/webm/ou-est-l-amour.webm';

import SisypheWebm from '../videos/webm/sisyphe-alej-elo.webm';

import UnePremiereRencontreWebm from '../videos/webm/une-premiere-rencontre.webm';
import UneTasseDamourWebm from '../videos/webm/une-tasse-d-amour.webm';
import VieDeReveWebm from '../videos/webm/vie-de-reve.webm';

import DefleureeWebm from '../videos/webm/defleuree.webm';
import TrentaineRencontresWebm from '../videos/webm/trentaine-rencontres.webm';
import PremierBaiserWebm from '../videos/webm/premier-baiser.webm';

export default {
    locale: getLocaleFromLocation(),
    messages: {
        'meta.title': 'DM-moi',
    },
    routes: {
        home: '/',
        info: '/info',
    },
    groups: {
        'group-1': {
            title: 'DM-moi',
            description: '',
            videos: [
                {
                    name: 'Présentation',
                    author: '',
                    code: 'presentation',
                    thumbnail: PresentationThumb,
                    sources: {
                        mp4: PresentationMp4,
                        webm: null,
                    },
                },
                {
                    name: 'Sisyphe',
                    author: 'Alejandro Shpori, Elorane Spriet',
                    code: 'sisiphe',
                    thumbnail: SisypheThumb,
                    sources: {
                        mp4: SisypheMp4,
                        webm: SisypheWebm,
                    },
                },
                {
                    name: 'Love Triangle',
                    author: '',
                    code: 'love-triangle',
                    thumbnail: LoveTriangleThumb,
                    sources: {
                        mp4: LoveTriangleMp4,
                        webm: LoveTriangleWebm,
                    },
                },
                {
                    name: 'Amitié et amour',
                    author: 'Nada Al-Rawi, Rim El Bahi',
                    code: 'amitie-et-amour',
                    thumbnail: AmitieEtAmourThumb,
                    sources: {
                        mp4: AmitieEtAmourMp4,
                        webm: AmitieEtAmourWebm,
                    },
                },
                {
                    name: 'Vie de rêve',
                    author: '',
                    code: 'vie-de-reve',
                    thumbnail: VieDeReveThumb,
                    sources: {
                        mp4: VieDeReveMp4,
                        webm: VieDeReveWebm,
                    },
                },
                {
                    name: "Une tasse d'amour",
                    author: 'Natasha Marie C. Sanchez, Danya',
                    code: 'une-tasse-d-amour',
                    thumbnail: UneTasseDamourThumb,
                    sources: {
                        mp4: UneTasseDamourMp4,
                        webm: UneTasseDamourWebm,
                    },
                },
                {
                    name: 'Histoire de Pun Pun',
                    author: '',
                    code: 'histoire-de-pun-pun',
                    thumbnail: HistoireDePunPunThumb,
                    sources: {
                        mp4: HistoireDePunPunMp4,
                        webm: HistoireDePunPunWebm,
                    },
                },
                {
                    name: 'Une première rencontre',
                    author: '',
                    code: 'une-premiere-rencontre',
                    thumbnail: UnePremiereRencontreThumb,
                    sources: {
                        mp4: UnePremiereRencontreMp4,
                        webm: UnePremiereRencontreWebm,
                    },
                },
                {
                    name: "Battement d'ailes",
                    author: '',
                    code: 'battement-d-ailes',
                    thumbnail: BattementDailesThumb,
                    sources: {
                        mp4: BattementDailesMp4,
                        webm: BattementDailesWebm,
                    },
                },
                {
                    name: "Où est l'amour",
                    author: '',
                    code: 'ou-est-l-amour',
                    thumbnail: OuEstLamourThumb,
                    sources: {
                        mp4: OuEstLamourMp4,
                        webm: OuEstLamourWebm,
                    },
                },
                {
                    name: 'Mon premier baiser',
                    author: 'Mireille Tawfik',
                    code: 'premier-baiser',
                    thumbnail: PremierBaiserThumb,
                    sources: {
                        mp4: PremierBaiserMp4,
                        webm: PremierBaiserWebm,
                    },
                },
                {
                    name: 'Défleurée',
                    author: 'Mireille Tawfik',
                    code: 'defleuree',
                    thumbnail: DefleureeThumb,
                    sources: {
                        mp4: DefleureeMp4,
                        webm: DefleureeWebm,
                    },
                },
                {
                    name: 'La trentaine et les sites de rencontre',
                    author: 'Mireille Tawfik',
                    code: 'trentaine-rencontres',
                    thumbnail: TrentaineRencontresThumb,
                    sources: {
                        mp4: TrentaineRencontresMp4,
                        webm: TrentaineRencontresWebm,
                    },
                },
            ],
        },
    },
};
