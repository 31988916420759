import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    className: null,
};

const SponsorsMainIcon = ({ color, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="196.8px"
        height="59.4px"
        viewBox="0 0 196.8 60"
        xmlSpace="preserve"
        className={className}
    >
        <g>
            <g>
                <g>
                    <polygon
                        id="XMLID_67_"
                        fill={color}
                        points="15.9,15.8 15.9,12.6 15.9,0 18.3,2.5 15.9,15.8 "
                    />
                    <polygon
                        id="XMLID_66_"
                        fill={color}
                        points="20.8,4.9 23,7.1 15.9,22.7 15.9,20.6 20.8,4.9 "
                    />
                    <polygon
                        id="XMLID_65_"
                        fill={color}
                        points="24.8,8.9 26.9,11.1 15.9,24.7 15.9,23.8 24.8,8.9 "
                    />
                    <polygon
                        id="XMLID_64_"
                        fill={color}
                        points="16.7,25.2 15.9,25.2 41.1,0 41.1,7.5 16.7,25.2 "
                    />
                    <polygon
                        id="XMLID_63_"
                        fill={color}
                        points="17.4,25.2 19.5,25.2 41.1,17.1 41.1,11.3 17.4,25.2 "
                    />
                    <polygon
                        id="XMLID_62_"
                        fill={color}
                        points="21.5,25.2 28.5,25.2 41.1,25.2 41.1,19.8 21.5,25.2 "
                    />
                    <path
                        id="XMLID_47_"
                        fill={color}
                        d="M50.4,53.9c0-0.2-0.1-0.3-0.3-0.3h-2v-4.9c0-0.2-0.2-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3v5.3
            c0,0.2,0.2,0.3,0.3,0.3h2.3C50.3,54.2,50.4,54.1,50.4,53.9 M45.2,52.2h-1.7l0.8-2.7L45.2,52.2L45.2,52.2z M46.4,54
            c0,0,0-0.1,0-0.1l-1.7-5.3c-0.1-0.2-0.2-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2l-1.7,5.3c0,0,0,0.1,0,0.1c0,0.2,0.1,0.3,0.3,0.3
            c0.1,0,0.2-0.1,0.3-0.2l0.4-1.2h2.1l0.4,1.2c0.1,0.2,0.2,0.2,0.3,0.2C46.3,54.3,46.4,54.2,46.4,54 M41.8,53.9
            c0-0.2-0.1-0.3-0.3-0.3h-2.3v-2.1h1.6c0.2,0,0.3-0.2,0.3-0.3c0-0.2-0.1-0.3-0.3-0.3h-1.6V49h2.3c0.2,0,0.3-0.2,0.3-0.3
            c0-0.2-0.1-0.3-0.3-0.3h-2.7c-0.2,0-0.3,0.1-0.3,0.3v5.2c0,0.2,0.2,0.3,0.3,0.3h2.7C41.7,54.2,41.8,54.1,41.8,53.9 M41.2,47
            c0-0.2-0.1-0.3-0.3-0.3c0,0-0.1,0-0.1,0l-1.3,0.6c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.2,0.1,0.3,0.3,0.3c0,0,0.1,0,0.1,0l1.3-0.6
            C41.1,47.2,41.2,47.1,41.2,47 M36.7,50.1c0,0.5-0.4,1-0.9,1h-1.1v-2h1.1C36.3,49,36.7,49.5,36.7,50.1L36.7,50.1L36.7,50.1z
             M37.3,50.1L37.3,50.1c0-1-0.7-1.7-1.6-1.7h-1.4c-0.2,0-0.3,0.1-0.3,0.3V54c0,0.2,0.2,0.3,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3v-2.3
            h0.9l1.2,2.4c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0,0.3-0.1,0.3-0.3c0,0,0-0.1,0-0.1l-1.1-2.2C36.9,51.4,37.3,50.8,37.3,50.1 M33.1,48.7
            c0-0.2-0.1-0.3-0.3-0.3h-2.5c-0.2,0-0.3,0.2-0.3,0.3c0,0.2,0.1,0.3,0.3,0.3h0.9V54c0,0.2,0.2,0.3,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3
            V49h0.9C33,49,33.1,48.9,33.1,48.7 M29,53.9v-5.3c0-0.2-0.2-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3v4.1l-1.8-4.2
            c-0.1-0.2-0.2-0.3-0.4-0.3c-0.2,0-0.4,0.1-0.4,0.3V54c0,0.2,0.2,0.3,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3v-4.1l1.8,4.2
            c0.1,0.3,0.3,0.3,0.4,0.3C28.9,54.3,29,54.2,29,53.9 M23.7,52.7c0,0.5-0.4,1-1,1h-0.1c-0.5,0-1-0.4-1-1v-2.7c0-0.5,0.4-1,1-1h0.1
            c0.5,0,1,0.4,1,1V52.7L23.7,52.7z M24.4,52.7v-2.7c0-0.9-0.7-1.6-1.6-1.6h-0.1c-0.9,0-1.6,0.7-1.6,1.6v2.7c0,0.9,0.7,1.6,1.6,1.6
            h0.1C23.6,54.3,24.4,53.6,24.4,52.7 M8,53.9c0-0.2-0.1-0.3-0.3-0.3H5.4v-2.1H7c0.2,0,0.3-0.2,0.3-0.3c0-0.2-0.1-0.3-0.3-0.3H5.4
            V49h2.3C7.9,49,8,48.9,8,48.7c0-0.2-0.1-0.3-0.3-0.3H5.1c-0.2,0-0.3,0.1-0.3,0.3v5.2c0,0.2,0.2,0.3,0.3,0.3h2.7
            C7.9,54.2,8,54.1,8,53.9 M2.7,52.6c0,0.5-0.4,1-1,1H0.7V49h1.1c0.5,0,1,0.4,1,1V52.6L2.7,52.6z M3.3,52.6V50
            c0-0.9-0.7-1.6-1.6-1.6H0.4c-0.2,0-0.3,0.1-0.3,0.3v5.2c0,0.2,0.2,0.3,0.3,0.3h1.4C2.6,54.2,3.3,53.5,3.3,52.6"
                    />
                    <path
                        id="XMLID_38_"
                        fill={color}
                        d="M31.6,44.2c0-1.1-0.8-1.4-1.7-1.9c-0.5-0.2-1-0.4-1-1c0-0.3,0.2-0.8,0.9-0.8h0.1
            c0.5,0,0.9,0.3,0.9,0.7c0,0.2,0.2,0.3,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3c0-0.8-0.7-1.4-1.5-1.4h-0.1c-0.9,0-1.6,0.6-1.6,1.5
            c0,1.1,0.8,1.3,1.6,1.7c0.5,0.3,1,0.5,1,1.2c0,0.6-0.3,0.9-1,0.9h-0.1c-0.6,0-1-0.3-1-0.9c0-0.2-0.2-0.3-0.3-0.3
            c-0.2,0-0.3,0.1-0.3,0.3c0,1,0.8,1.6,1.7,1.6H30C31,45.8,31.6,45.2,31.6,44.2 M27.7,40.2c0-0.2-0.1-0.3-0.3-0.3h-2.5
            c-0.2,0-0.3,0.2-0.3,0.3c0,0.2,0.1,0.3,0.3,0.3h0.9v4.9c0,0.2,0.2,0.3,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3v-4.9h0.9
            C27.5,40.6,27.7,40.4,27.7,40.2 M23.3,41.6c0,0.5-0.4,1-0.9,1h-1.1v-2h1.1C22.9,40.6,23.3,41,23.3,41.6L23.3,41.6L23.3,41.6z
             M23.9,41.6L23.9,41.6c0-1-0.7-1.7-1.6-1.7h-1.4c-0.2,0-0.3,0.2-0.3,0.3v5.3c0,0.2,0.2,0.3,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3v-2.3
            h0.9l1.2,2.4c0.1,0.1,0.2,0.2,0.3,0.2c0.2,0,0.3-0.1,0.3-0.3c0,0,0-0.1,0-0.1l-1-2.2C23.5,43,23.9,42.3,23.9,41.6 M12,44.2
            c0-1.1-0.8-1.4-1.7-1.9c-0.5-0.2-1-0.4-1-1c0-0.3,0.2-0.8,0.9-0.8h0.1c0.5,0,0.9,0.3,0.9,0.7c0,0.2,0.2,0.3,0.3,0.3
            c0.2,0,0.3-0.1,0.3-0.3c0-0.8-0.7-1.4-1.5-1.4h-0.1c-0.9,0-1.6,0.6-1.6,1.5c0,1.1,0.8,1.3,1.6,1.7c0.5,0.3,1,0.5,1,1.2
            c0,0.6-0.3,0.9-1,0.9h-0.1c-0.6,0-1-0.3-1-0.9c0-0.2-0.2-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3c0,1,0.8,1.6,1.7,1.6h0.1
            C11.4,45.8,12,45.2,12,44.2 M8,45.5c0-0.2-0.1-0.3-0.3-0.3H5.3v-2.1H7c0.2,0,0.3-0.2,0.3-0.3c0-0.2-0.1-0.3-0.3-0.3H5.3v-1.9h2.3
            c0.2,0,0.3-0.2,0.3-0.3c0-0.2-0.1-0.3-0.3-0.3H5c-0.2,0-0.3,0.2-0.3,0.3v5.2c0,0.2,0.2,0.3,0.3,0.3h2.7C7.9,45.8,8,45.6,8,45.5
             M2.7,44.2c0,0.5-0.4,1-1,1H0.6v-4.6h1.1c0.5,0,1,0.4,1,1V44.2L2.7,44.2z M3.3,44.2v-2.6c0-0.9-0.7-1.6-1.6-1.6H0.3
            c-0.2,0-0.3,0.2-0.3,0.3v5.2c0,0.2,0.2,0.3,0.3,0.3h1.4C2.6,45.8,3.3,45,3.3,44.2"
                    />
                    <path
                        id="XMLID_30_"
                        fill={color}
                        d="M43.5,37c0-0.2-0.1-0.3-0.3-0.3h-2v-4.9c0-0.2-0.2-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3V37
            c0,0.2,0.2,0.3,0.3,0.3h2.3C43.4,37.3,43.5,37.1,43.5,37 M38.9,37v-5.3c0-0.2-0.2-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3V37
            c0,0.2,0.2,0.3,0.3,0.3C38.8,37.3,38.9,37.2,38.9,37 M37.2,37c0-0.2-0.1-0.3-0.3-0.3h-2.3v-2.1h1.6c0.2,0,0.3-0.2,0.3-0.3
            c0-0.2-0.1-0.3-0.3-0.3h-1.6v-1.9h2.3c0.2,0,0.3-0.2,0.3-0.3c0-0.2-0.1-0.3-0.3-0.3h-2.7c-0.2,0-0.3,0.2-0.3,0.3V37
            c0,0.2,0.2,0.3,0.3,0.3h2.7C37.1,37.3,37.2,37.1,37.2,37 M32.7,35.8c0-1.2-0.8-1.4-1.7-1.9c-0.5-0.2-1-0.4-1-1
            c0-0.3,0.2-0.8,0.9-0.8h0.1c0.5,0,0.9,0.3,0.9,0.7c0,0.2,0.2,0.3,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3c0-0.8-0.7-1.4-1.5-1.4H31
            c-0.9,0-1.6,0.6-1.6,1.5c0,1.1,0.8,1.3,1.6,1.7c0.5,0.3,1,0.5,1,1.2c0,0.6-0.3,0.9-1,0.9H31c-0.5,0-1-0.3-1-0.9
            c0-0.2-0.2-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3c0,1,0.8,1.6,1.7,1.6h0.1C32.1,37.3,32.7,36.7,32.7,35.8 M28.1,37v-5.3
            c0-0.2-0.2-0.3-0.3-0.3c-0.2,0-0.3,0.1-0.3,0.3v4.1l-1.8-4.2c-0.1-0.2-0.2-0.3-0.4-0.3c-0.2,0-0.4,0.1-0.4,0.3V37
            c0,0.2,0.2,0.3,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3v-4.1l1.8,4.2c0.1,0.3,0.3,0.3,0.4,0.3C28,37.3,28.1,37.2,28.1,37 M22.8,35.7
            c0,0.5-0.4,1-1,1h-0.1c-0.5,0-1-0.4-1-1V33c0-0.5,0.4-1,1-1h0.1c0.5,0,1,0.4,1,1V35.7L22.8,35.7z M23.4,35.7V33
            c0-0.9-0.7-1.6-1.6-1.6h-0.1c-0.9,0-1.6,0.7-1.6,1.6v2.7c0,0.9,0.7,1.6,1.6,1.6h0.1C22.7,37.3,23.4,36.6,23.4,35.7"
                    />
                    <path
                        id="XMLID_29_"
                        fill={color}
                        d="M19.7,54v-5.3c0-0.2-0.1-0.4-0.4-0.4h0c-0.2,0-0.3,0.1-0.4,0.2l-1.3,3l-1.4-3
            c-0.1-0.1-0.2-0.2-0.4-0.2h0c-0.3,0-0.4,0.1-0.4,0.4V54c0,0.2,0.2,0.3,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3v-4.1l1.2,2.6
            c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.3-0.1,0.4-0.2l1.1-2.6V54c0,0.2,0.2,0.3,0.3,0.3C19.5,54.3,19.7,54.2,19.7,54"
                    />
                    <path
                        id="XMLID_26_"
                        fill={color}
                        d="M18.4,43.7h-1.7l0.8-2.7L18.4,43.7L18.4,43.7z M19.6,45.5c0,0,0-0.1,0-0.1l-1.7-5.3
            c0-0.2-0.2-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2l-1.7,5.3c0,0,0,0.1,0,0.1c0,0.2,0.1,0.3,0.3,0.3c0.1,0,0.2-0.1,0.3-0.2l0.4-1.2
            h2.1l0.4,1.2c0,0.2,0.2,0.2,0.3,0.2C19.4,45.8,19.6,45.7,19.6,45.5"
                    />
                    <path
                        id="XMLID_25_"
                        fill={color}
                        d="M19.2,36L19.2,36c0-0.3-0.2-0.4-0.3-0.4c-0.1,0-0.3,0.1-0.3,0.3c-0.1,0.5-0.5,0.8-1,0.8h-0.1
            c-0.5,0-1-0.4-1-1V33c0-0.5,0.4-1,1-1h0.1c0.5,0,0.9,0.4,1,0.8c0,0.2,0.2,0.3,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3v-0.1
            c-0.1-0.8-0.8-1.4-1.6-1.4h-0.1c-0.9,0-1.6,0.7-1.6,1.6v2.7c0,0.9,0.7,1.6,1.6,1.6h0.1C18.4,37.3,19,36.7,19.2,36"
                    />
                    <polygon
                        id="XMLID_24_"
                        fill={color}
                        points="75.6,39.9 77,39.9 78.7,44.7 78.7,44.7 80.5,39.9 81.7,39.9 81.7,45.7 80.8,45.7
            80.8,40.7 80.8,40.7 79,45.7 78.2,45.7 76.4,40.7 76.4,40.7 76.4,45.7 75.6,45.7 75.6,39.9 "
                    />
                    <path
                        id="XMLID_21_"
                        fill={color}
                        d="M82.4,43.7c0-1.3,0.6-2.1,1.9-2.1c1.3,0,1.9,0.7,1.9,2.1c0,1.4-0.6,2.1-1.9,2.1
            C83.1,45.8,82.4,45.1,82.4,43.7L82.4,43.7z M84.3,45.5c0.8,0,0.9-1,0.9-1.7c0-0.7-0.1-1.7-0.9-1.7c-0.8,0-0.9,1-0.9,1.7
            C83.4,44.5,83.5,45.5,84.3,45.5L84.3,45.5z"
                    />
                    <path
                        id="XMLID_20_"
                        fill={color}
                        d="M86.9,41.7h0.9v1h0c0.1-0.3,0.6-1,1.5-1c0.8,0,1.1,0.6,1.1,1.2v2.9h-0.9v-2.5
            c0-0.5-0.1-1-0.5-1c-0.9,0-1.2,1.3-1.2,1.8v1.7h-0.9V41.7L86.9,41.7z"
                    />
                    <path
                        id="XMLID_19_"
                        fill={color}
                        d="M93.8,45.8c-0.2,0-0.4,0.1-0.6,0.1c-0.9,0-1.4-0.2-1.4-1v-2.6h-0.7v-0.4h0.7v-0.9l0.9-0.5
            v1.4h1.2v0.4h-1.2v2.3c0,0.6,0.1,0.9,0.7,0.9c0.1,0,0.3,0,0.4-0.1V45.8L93.8,45.8z"
                    />
                    <path
                        id="XMLID_18_"
                        fill={color}
                        d="M94.5,41.7h0.9v1h0c0.3-0.5,0.6-1.1,1.4-1.1v0.9c-0.9,0-1.4,0.3-1.4,1.4v1.8h-0.9V41.7
            L94.5,41.7z"
                    />
                    <path
                        id="XMLID_14_"
                        fill={color}
                        d="M100.5,45.6c-0.4,0.1-0.7,0.2-1.3,0.2c-1.2,0-2.2-0.7-2.2-2.3c0-1.1,0.5-1.9,1.8-1.9
            c1.4,0,1.7,0.9,1.7,1.6H98c0,0.8,0.4,2.1,1.7,2.1c0.3,0,0.6-0.1,0.8-0.2V45.6L100.5,45.6z M99.7,42.9c0-0.3-0.1-0.9-0.8-0.9
            c-0.7,0-0.8,0.7-0.9,0.9H99.7L99.7,42.9z M99,39.9h1L99.1,41h-0.7L99,39.9L99,39.9z"
                    />
                    <path
                        id="XMLID_11_"
                        fill={color}
                        d="M103.5,45.2L103.5,45.2c-0.2,0.2-0.5,0.6-1.2,0.6c-0.7,0-1.2-0.4-1.2-1
            c0-1.2,1.8-1.3,2.4-1.3v-0.4c0-0.4,0-1.2-0.6-1.2c-0.6,0-0.7,0.4-0.7,0.9h-1c0-0.6,0.3-1.2,1.7-1.2c1.4,0,1.5,0.7,1.5,1.5v2.6
            h-0.9V45.2L103.5,45.2z M103.5,43.9c-0.4,0-1.4,0.1-1.4,0.8c0,0.4,0.2,0.6,0.5,0.6c0.3,0,0.9-0.3,0.9-1V43.9L103.5,43.9z"
                    />
                    <polygon
                        id="XMLID_10_"
                        fill={color}
                        points="105.4,39.9 106.3,39.9 106.3,45.7 105.4,45.7 105.4,39.9 "
                    />
                    <path
                        id="XMLID_4_"
                        fill={color}
                        d="M112.1,39.2c-0.3-0.4-0.7-0.7-1.2-0.7c-0.8,0-1.4,0.6-1.4,1.4c0,0.1,0,0.2,0,0.3
            c-0.1,0-0.2,0-0.3,0c-0.8,0-1.4,0.6-1.4,1.4c0,0.5,0.3,0.9,0.7,1.2c-0.4,0.3-0.7,0.7-0.7,1.2c0,0.8,0.7,1.4,1.4,1.4
            c0.1,0,0.2,0,0.3,0c0,0.1,0,0.2,0,0.3c0,0.8,0.6,1.4,1.4,1.4c0.5,0,0.9-0.2,1.2-0.7c0.3,0.4,0.7,0.7,1.2,0.7
            c0.8,0,1.4-0.6,1.4-1.4c0-0.1,0-0.2,0-0.3c0.1,0,0.2,0,0.3,0c0.8,0,1.4-0.6,1.4-1.4c0-0.5-0.3-0.9-0.7-1.2
            c0.4-0.3,0.7-0.7,0.7-1.2c0-0.8-0.7-1.4-1.5-1.4c-0.1,0-0.2,0-0.3,0c0-0.1,0-0.2,0-0.3c0-0.8-0.6-1.4-1.4-1.4
            C112.9,38.5,112.4,38.7,112.1,39.2L112.1,39.2z M110.2,40.6L110.2,40.6c-0.1-0.1-0.3-0.3-0.3-0.7l0,0c0-0.6,0.5-1,1-1
            c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.7v2.2c0,0.3-0.2,0.4-0.4,0.4h-2.3c-0.3,0-0.5-0.1-0.7-0.3
            c-0.1-0.1-0.3-0.3-0.3-0.7c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.3,0.7-0.3c0.3,0,0.6,0.2,0.7,0.3c0,0,0.1,0.1,0.1,0.1
            c0,0.1,0,0.1,0,0.2c0,0.3,0.3,0.6,0.6,0.6c0.2,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.3,0.2-0.4c0-0.1-0.1-0.3-0.2-0.4
            c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1,0-0.2,0-0.2,0C110.3,40.7,110.2,40.6,110.2,40.6L110.2,40.6z M112.4,39.9c0-0.3,0.1-0.5,0.3-0.7
            c0.1-0.1,0.3-0.3,0.7-0.3c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.7l0,0c0,0.3-0.2,0.6-0.3,0.7c0,0-0.1,0.1-0.1,0.1
            c0,0-0.1,0-0.2,0c-0.3,0-0.6,0.3-0.6,0.6c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.3,0.2,0.4,0.2c0,0,0,0,0,0c0.1,0,0.3-0.1,0.4-0.2
            c0.1-0.1,0.2-0.2,0.2-0.4v0c0-0.1,0-0.2,0-0.2c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.3-0.3,0.7-0.3c0.6,0,1,0.5,1,1
            c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.2-0.4,0.3-0.7,0.3h-2.2c-0.3,0-0.4-0.3-0.4-0.4l0,0V39.9L112.4,39.9z M108.5,44.7
            c-0.2-0.2-0.3-0.4-0.3-0.7l0,0c0-0.3,0.1-0.5,0.3-0.7c0.2-0.2,0.4-0.3,0.7-0.3h2.2c0.3,0,0.4,0.3,0.4,0.4v2.3
            c0,0.3-0.1,0.5-0.3,0.7c-0.1,0.1-0.3,0.3-0.7,0.3c-0.5,0-1-0.4-1-1l0,0c0-0.3,0.2-0.6,0.3-0.7c0,0,0.1-0.1,0.1-0.1
            c0.1,0,0.1,0,0.2,0c0.3,0,0.6-0.3,0.6-0.6c0-0.2-0.1-0.3-0.2-0.4c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2
            c-0.1,0.1-0.2,0.2-0.2,0.4v0c0,0.1,0,0.2,0,0.2c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.3,0.3-0.7,0.3C108.9,45,108.7,44.9,108.5,44.7
            L108.5,44.7z M112.7,46.4c-0.2-0.2-0.3-0.4-0.3-0.7v-2.2c0-0.3,0.3-0.4,0.4-0.4h2.3c0.5,0,1,0.4,1,1c0,0.5-0.4,1-1,1
            c-0.3,0-0.6-0.2-0.7-0.3c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1,0-0.2v0c0-0.3-0.3-0.6-0.6-0.6c-0.2,0-0.3,0.1-0.4,0.2
            c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.2,0.4,0.2c0.1,0,0.2,0,0.2,0c0,0,0.1,0.1,0.1,0.1
            c0.1,0.1,0.3,0.3,0.3,0.7v0c0,0.6-0.5,1-1,1C113.1,46.7,112.8,46.6,112.7,46.4L112.7,46.4z"
                    />
                    <polygon
                        id="XMLID_3_"
                        fill={color}
                        points="62.9,31.4 62.9,54.3 63.1,54.3 63.1,31.4 62.9,31.4 "
                    />
                </g>
            </g>
        </g>
        <g>
            <path
                fill={color}
                d="M189.3,11.8C184,6.1,177.5,3,169.7,2.7c-7.8-0.4-14.8,2.1-20.5,7.4c-5.7,5.2-8.8,11.8-9.1,19.6
    c-0.4,7.9,2.1,14.8,7.5,20.6c5.3,5.7,11.8,8.8,19.6,9.1c7.9,0.3,14.8-2.1,20.6-7.5c6-5.6,9-12.6,9.1-20.8
    C196.8,23.3,193.7,16.5,189.3,11.8z M186.7,50.4c-5.5,5.2-12.1,7.6-19.6,7.3c-7.1-0.3-13.3-3.2-18.2-8.4
    c-5.2-5.5-7.6-12.1-7.3-19.6c0.3-7.1,3.2-13.3,8.4-18.2c5.5-5.2,12.1-7.6,19.6-7.3c7.1,0.3,13.2,3.2,18.2,8.4
    c4.4,4.6,7.3,11.1,7.3,18.3C195,38.6,192.3,45.2,186.7,50.4z"
            />
            <path
                fill={color}
                d="M182.5,44.8c-0.3-0.2-0.3-0.3-0.3-0.6c0-4,0-7.9,0-11.9c0-0.8-0.6-1.2-1.3-0.9c-0.9,0.4-1.8,0.8-2.7,1.2
    c-1.8-3-3.5-6-5.3-8.9c0.6-0.1,1.2-0.1,1.7-0.2c1.3-0.3,2.3-1.1,3-2.2c0.6-1,0.8-2.2,0.6-3.3c-0.2-1.3-0.9-2.4-1.9-3.2
    c-0.7-0.6-1.6-0.9-2.5-0.9c-2.3,0-4.7,0-7,0c-0.1,0-0.2,0-0.3,0.1c-0.5,0.2-0.5,0.6-0.5,1c0,4.5,0,8.9,0,13.4c0,0.1,0,0.2,0,0.4
    c-2.9,0-5.7,0-8.6,0c0-0.1,0-0.2,0-0.4c0-1.8,0-3.6,0-5.5c0-1.8,0-3.5,0-5.3c0-0.1,0.1-0.3,0.2-0.4c1.3-0.7,1.7-2.4,0.8-3.5
    c-0.7-0.9-1.9-1.1-2.9-0.6c-0.8,0.5-1.2,1.2-1.1,2.1c0,0.9,0.4,1.5,1.2,1.9c0.2,0.1,0.3,0.2,0.3,0.5c0,3.9,0,7.9,0,11.8
    c0,0.6,0.3,0.9,0.9,0.9c2.5,0,5.1,0,7.6,0c0.1,0,0.2,0,0.4,0c0,0.2-0.1,0.4-0.1,0.6c0.1,0.9,0.4,1.6,1.3,2c0.1,0,0.2,0.1,0.2,0.2
    c0,0.3,0,0.7,0,1c-0.2,0-0.3,0-0.4,0c-2.9,0-5.7,0-8.6,0c-0.2,0-0.5,0-0.7,0.1c-1,0.1-1.8,0.6-2.4,1.5c-0.6,0.9-0.9,2-0.6,3.1
    c0.3,1.3,1.1,2.2,2.4,2.6c0.6,0.2,1.2,0.2,1.8,0.2c0.7,0,1.3-0.1,2,0c1.5,0.2,2.2,1.7,1.9,3c-0.2,0.9-0.8,1.5-1.7,1.7
    c-0.3,0.1-0.6,0-0.9,0c-1,0-2,0-3,0.1c-0.4,0-0.7,0.5-0.7,0.8c0,0.4,0.4,0.8,0.8,0.8c1,0,1.9,0,2.9,0c0.4,0,0.7,0.1,1,0
    c1.3-0.2,2.2-0.9,2.8-2.1c0.4-0.8,0.5-1.7,0.4-2.6c-0.1-0.9-0.5-1.7-1.1-2.3c-0.8-0.7-1.7-1.1-2.8-1.1c-0.8,0-1.6,0-2.4,0
    c-0.1,0-0.2,0-0.3-0.1c-1-0.2-1.7-1.3-1.5-2.4c0.2-0.9,1-1.7,2-1.7c2.9,0,5.9,0,8.8,0c0.1,0,0.2,0,0.3,0c0,0.1,0,0.3,0,0.4
    c0,3.9,0,7.8,0,11.7c0,0.1,0,0.2,0,0.3c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.8-0.4,0.8-0.9c0-4.7,0-9.4,0-14.1c0-0.1,0-0.2,0-0.4
    c0.2,0.1,0.3,0.1,0.4,0.2c1.6,0.7,3.2,1.3,4.9,2c0.2,0.1,0.3,0.2,0.3,0.5c0,3.9,0,7.9,0,11.8c0,0.1,0,0.3,0.1,0.4
    c0.2,0.4,0.6,0.6,0.9,0.5c0.4-0.1,0.7-0.4,0.7-0.9c0-4,0-8,0-11.9c0-0.2,0.1-0.3,0.3-0.4c1.2-0.5,2.4-1.1,3.6-1.6
    c0.6-0.3,1.1-0.5,1.7-0.8c0,0.1,0,0.3,0,0.4c0,3.4,0,6.8,0,10.2c0,0.4,0,0.6-0.4,0.8c-0.7,0.4-1.1,1.1-1.1,2c0,0.7,0.3,1.3,0.8,1.7
    c0.6,0.5,1.2,0.7,1.9,0.5c0.8-0.2,1.4-0.6,1.6-1.3C183.9,46.5,183.6,45.5,182.5,44.8z M156.6,15.7c-0.4,0-0.6-0.2-0.6-0.6
    c0-0.3,0.3-0.7,0.6-0.7c0.3,0,0.6,0.3,0.6,0.6C157.2,15.4,157,15.7,156.6,15.7z M167.8,15.5c0.1,0,0.2,0,0.3,0c1.8,0,3.7,0,5.5,0
    c1.6,0,2.9,1.2,3.2,2.8c0.3,1.9-1,3.3-2.6,3.7c-0.3,0.1-0.5,0.1-0.8,0.1c-1.8,0-3.7,0-5.6,0C167.8,19.8,167.8,17.7,167.8,15.5z
     M166.9,31.5c-0.3,0-0.6-0.3-0.6-0.6c0-0.4,0.2-0.6,0.6-0.6c0.3,0,0.6,0.3,0.6,0.6C167.6,31.3,167.2,31.5,166.9,31.5z M174.3,34.4
    c-0.1,0-0.2,0-0.3,0c-1.7-0.7-3.5-1.4-5.2-2.1c0.4-0.6,0.5-1.3,0.4-2c-0.1-0.7-0.5-1.1-1.1-1.4c-0.2-0.1-0.3-0.2-0.3-0.4
    c0-1.5,0-3,0-4.5c0-0.1,0-0.2,0-0.3c0.5,0,0.9,0,1.4,0c0.5,0,1.1,0,1.6,0c0.2,0,0.4,0.1,0.5,0.3c0.9,1.6,1.9,3.3,2.9,4.9
    c0.9,1.5,1.7,2.9,2.6,4.4c0,0,0,0.1,0.1,0.1C175.9,33.7,175.1,34.1,174.3,34.4z M181.4,47.4c-0.3,0-0.6-0.4-0.6-0.7
    c0-0.3,0.3-0.6,0.6-0.6c0.4,0,0.6,0.3,0.6,0.6C182,47.1,181.6,47.4,181.4,47.4z"
            />
        </g>
    </svg>
);

SponsorsMainIcon.propTypes = propTypes;
SponsorsMainIcon.defaultProps = defaultProps;

export default SponsorsMainIcon;
