import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    topColor: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    topColor: '#CC3399',
    className: null,
};

const HeartIcon = ({ color, topColor, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="405px"
        height="357.4px"
        viewBox="0 0 405 357.4"
        xmlSpace="preserve"
        className={className}
    >
        <g>
            <path
                fill={color}
                d="M94,249l31,31c42.8-42.8,112.1-42.8,154.9,0l31-31C251,189.1,153.9,189.1,94,249z"
            />
            <path
                fill={color}
                d="M156,310.9l46.5,46.5l46.5-46.5C223.3,285.3,181.7,285.3,156,310.9z"
            />
            <path
                fill={color}
                d="M32.1,187l31,31c77-77,201.8-77,278.8,0l31-31C278.8,92.9,126.2,92.9,32.1,187z"
            />
            <path
                fill={topColor}
                d="M397.4,149.7c15.5-39.3,7.3-85.8-24.5-117.6c-42.8-42.8-112.1-42.8-154.9,0c-6,6-11.2,12.5-15.5,19.4
            c-4.3-6.9-9.5-13.4-15.5-19.4c-42.8-42.8-112.1-42.8-154.9,0C0.3,63.9-7.9,110.4,7.6,149.7C117.1,46.9,287.9,46.9,397.4,149.7z"
            />
        </g>
    </svg>
);

HeartIcon.propTypes = propTypes;
HeartIcon.defaultProps = defaultProps;

export default HeartIcon;
