import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    stroke: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    stroke: 'currentColor',
    className: null,
};

const CloseIcon = ({ stroke, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="22.1px"
        height="22.1px"
        viewBox="0 0 22.1 22.1"
        xmlSpace="preserve"
        className={className}
    >
        <g>
            <line
                fill="none"
                stroke={stroke}
                strokeWidth={3}
                strokeMiterlimit={10}
                x1="21.1"
                y1="1.1"
                x2="1.1"
                y2="21.1"
            />
            <line
                fill="none"
                stroke={stroke}
                strokeWidth={3}
                strokeMiterlimit={10}
                x1="21.1"
                y1="21.1"
                x2="1.1"
                y2="1.1"
            />
        </g>
    </svg>
);

CloseIcon.propTypes = propTypes;
CloseIcon.defaultProps = defaultProps;

export default CloseIcon;
