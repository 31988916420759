import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    color: PropTypes.string,
    topColor: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    color: 'currentColor',
    topColor: '#CC3399',
    className: null,
};

const InfoIcon = ({ color, topColor, className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="30px"
        height="30px"
        viewBox="0 0 30 30"
        xmlSpace="preserve"
        className={className}
    >
        <circle fill={topColor} style={{ opacity: 0.2 }} cx="15" cy="15" r="15" />
        <g>
            <path
                fill={color}
                d="M13.2,16.2c0-0.2,0.1-0.3,0.1-0.5s0-0.3,0-0.4c0-0.2,0-0.4-0.1-0.5c-0.1-0.1-0.2-0.3-0.4-0.4
    c0.6-0.5,1.2-0.9,1.8-1.1c0.6-0.3,1.1-0.4,1.7-0.4c0.5,0,0.8,0.1,1.1,0.3c0.3,0.2,0.4,0.5,0.4,0.9c0,0.1,0,0.2,0,0.3
    c0,0.1,0,0.2-0.1,0.3l-0.9,4.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.2c0,0.3,0.1,0.5,0.2,0.7
    c0.1,0.2,0.3,0.2,0.6,0.2c0,0,0.1,0,0.1,0s0,0,0.1,0c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1,0,0.1-0.1c0.2-0.1,0.3-0.1,0.4-0.2
    c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.2,0.1c0.1,0.1,0.1,0.2,0.1,0.3c0,0.2-0.1,0.3-0.2,0.5c-0.1,0.2-0.2,0.4-0.4,0.6
    c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.2-0.6,0.3s-0.4,0.2-0.6,0.3c-0.3,0.1-0.6,0.2-1,0.3
    s-0.6,0.1-0.9,0.1c-0.7,0-1.3-0.2-1.8-0.6c-0.5-0.4-0.7-0.9-0.7-1.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0,0,0-0.1,0-0.1
    c0,0,0-0.1,0-0.1L13.2,16.2z M18.1,8.6c0.4,0.3,0.5,0.7,0.5,1.3c0,0.6-0.3,1.1-0.9,1.6c-0.6,0.5-1.3,0.7-2.1,0.7
    c-0.5,0-1-0.2-1.3-0.5c-0.3-0.3-0.5-0.7-0.5-1.2c0-0.6,0.3-1.2,0.8-1.7c0.5-0.5,1.2-0.7,1.9-0.7C17.2,8.1,17.7,8.2,18.1,8.6z"
            />
        </g>
    </svg>
);

InfoIcon.propTypes = propTypes;
InfoIcon.defaultProps = defaultProps;

export default InfoIcon;
