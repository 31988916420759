import { SET_VIDEO } from '../actions/SiteActions';

const initialState = {
    group: 'group-1',
    groups: {},
    video: null,
    textColor: '#FFE5BF',
    grayColor: '#fff',
};

const SiteReducer = (previousState, action) => {
    let state = previousState || initialState;
    if (typeof state.hydrated === 'undefined' || !state.hydrated) {
        state = {
            ...initialState,
            ...previousState,
            hydrated: true,
        };
    }
    switch (action.type) {
    case SET_VIDEO:
        return {
            ...state,
            video: action.payload === state.video ? null : action.payload,
            textColor: action.payload === state.video ? '#FFE5BF' : '#fff',
            grayColor: action.payload === state.video ? '#fff' : '#6D6E71',
        };
    default:
        return state;
    }
};

export default SiteReducer;
