import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { withUrlGenerator } from '@folklore/react-container';
import { withRouter } from 'react-router';

import HeartIcon from '../icons/Heart';
import InfoIcon from '../icons/Info';
import CloseIcon from '../icons/Close';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/layouts/header.scss';

const propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    color: PropTypes.string.isRequired,
    big: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    match: AppPropTypes.match.isRequired,
    urlGenerator: AppPropTypes.urlGenerator.isRequired,
};

const defaultProps = {
    description: null,
};

const Header = ({
    title, description, color, big, onClick, match, urlGenerator,
}) => {
    const isInfo = match.path === '/info';
    const route = isInfo ? 'home' : 'info';
    const showDescription = big && !isInfo;
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [styles.hidden]: isInfo,
                    [styles.big]: showDescription,
                },
            ])}
        >
            <button
                type="button"
                onClick={() => {
                    onClick(null);
                }}
                className={styles.button}
            >
                <HeartIcon color={color} className={styles.logo} />
                <h1 className={styles.name}>{title}</h1>
                {showDescription ? <p className={styles.description}>{description}</p> : null}
            </button>
            <Link to={urlGenerator.route(route)} className={styles.link}>
                <div
                    className={classNames([
                        styles.info,
                        {
                            [styles.close]: match.path === '/info',
                        },
                    ])}
                >
                    {match.path === '/info' ? (
                        <CloseIcon color={color} />
                    ) : (
                        <InfoIcon color={color} />
                    )}
                </div>
            </Link>
        </div>
    );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

const WithUrlGeneratorContainer = withUrlGenerator()(Header);
const WithRouterContainer = withRouter(WithUrlGeneratorContainer);

export default WithRouterContainer;
