import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withUrlGenerator } from '@folklore/react-container';
import { withRouter } from 'react-router';

import Sponsors from '../icons/Sponsors';
import SponsorsMain from '../icons/SponsorsMain';

import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/layouts/footer.scss';

const propTypes = {
    color: PropTypes.string.isRequired,
    match: AppPropTypes.match.isRequired,
    urlGenerator: AppPropTypes.urlGenerator.isRequired,
};

const Footer = ({ color, match, urlGenerator }) => (
    <div className={styles.container}>
        <Link to={urlGenerator.route('home')} className={styles.link}>
            {match.path === '/info' ? <Sponsors color={color} /> : <SponsorsMain color={color} />}
        </Link>
    </div>
);

Footer.propTypes = propTypes;

const WithUrlGeneratorContainer = withUrlGenerator()(Footer);
const WithRouterContainer = withRouter(WithUrlGeneratorContainer);

export default WithRouterContainer;
