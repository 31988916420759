/**
 * Constants
 */
export const SET_VIDEO = 'layout@SET_VIDEO';

/**
 * Actions creator
 */
export const setVideo = payload => ({
    type: SET_VIDEO,
    payload,
});
