import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';

import Header from './Header';
import Footer from './Footer';

import {
    setSize as setSizeActions,
    setFontsLoaded as setFontsLoadedActions,
} from '../../actions/LayoutActions';

import { setVideo as setVideoAction } from '../../actions/SiteActions';

import styles from '../../styles/layouts/main.scss';

const propTypes = {
    setFontsLoaded: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    setSize: PropTypes.func.isRequired,
    setVideo: PropTypes.func.isRequired,
    video: PropTypes.object, // eslint-disable-line
    group: PropTypes.object.isRequired, // eslint-disable-line
    grayColor: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
};

const defaultProps = {
    video: null,
};

class MainLayout extends Component {
    constructor(props) {
        super(props);

        this.onResize = this.onResize.bind(this);
        this.onFontsActive = this.onFontsActive.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    onFontsActive() {
        const { setFontsLoaded } = this.props;
        setFontsLoaded(true);
    }

    onResize() {
        const { setSize } = this.props;
        const { innerWidth, innerHeight } = window;
        setSize({
            width: innerWidth,
            height: innerHeight,
        });
    }

    render() {
        const {
            children, group, video, textColor, grayColor, setVideo,
        } = this.props;
        return (
            <div
                className={classNames([
                    styles.container,
                    {
                        [styles.dark]: video !== null,
                    },
                ])}
            >
                <Header
                    title={group.title}
                    description={group.description}
                    color={textColor}
                    big={video === null}
                    onClick={setVideo}
                />
                <div className={styles.inner}>
                    <div className={styles.content}>{children}</div>
                </div>
                <Footer color={grayColor} />
            </div>
        );
    }
}

MainLayout.propTypes = propTypes;
MainLayout.defaultProps = defaultProps;

const mapStateToProps = ({ layout, site }) => ({
    size: layout.size,
    group: site.groups[site.group],
    textColor: site.textColor,
    grayColor: site.grayColor,
    video: site.video,
    fontsLoaded: layout.fontsLoaded,
    isPrerender: site.isPrerender || false,
});
const mapDispatchToProps = dispatch => ({
    setSize: size => dispatch(setSizeActions(size)),
    setFontsLoaded: loaded => dispatch(setFontsLoadedActions(loaded)),
    setVideo: id => dispatch(setVideoAction(id)),
});
const WithStateContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(MainLayout);

export default WithStateContainer;
